import React, { useEffect, useState, useRef } from 'react';
import Table from './partials/TableUsed';
import Api from '../../../_helpers/Api';
import Pagination from '../../Pagination';
import { useHistory, useLocation } from 'react-router-dom';

//modals
import Question from '../../modals/messages/Question';
import Filter from './partials/Filter';
import History from './partials/History';
import EditPart from '../reservation/partials/modals/AddEditPart';
import ActivityModal from '../../modals/Activity';

import Skeleton from 'react-loading-skeleton';
import { trans } from '../../../_providers/Translation'
import Axios from 'axios';

let timeout;

function Used() {

    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const modalEditRef = useRef(null);
    const modalQuestionRef = useRef(null);
    const activityModalRef = useRef(null);

    const requestRef = useRef();

    const [parts, setParts] = useState({
        loading: true,
        data: [],
        total: 0,
        pages: 0,
        page: 1,
        filter: {
            stock: '',
            instock: '',
            customer_type: ''
        },
        setFilter: false,
        update: false,
    });

    const [performers, setPerformers] = useState([]);

    useEffect(() => {
        setParts(prev => ({
            ...prev,
            page: searchParams.get('page') || 1,
            filter: {
                ...prev.filter,
                category_id: searchParams.get('category_id') || '',
                name: searchParams.get('name') || '',
                number: searchParams.get('number') || '',
                supplier_id: searchParams.get('supplier_id') || '',
                customer_type: searchParams.get('customer_type') || '',
                status_id: searchParams.get('status_id') || '',
                start: searchParams.get('start') || '',
                end: searchParams.get('end') || '',
            },
            update: new Date().getTime()
        }))
    }, [location.search])

    useEffect(() => {
        if (parts.setFilter) {
            searchParams.set('page', parts.page);

            Object.entries(parts.filter).map(filter => {
                // if (filter[1]) {
                searchParams.set(filter[0], filter[1]);
                // }
            });

            history.push('?' + searchParams.toString());
        }

    }, [parts.setFilter]);

    useEffect(() => {
        if (parts.update) {
            loadParts();
        }
    }, [parts.update])

    useEffect(() => {
        let e = Api.get('partners/employees/technicians')
            .then(res => {
                // return res.data;
                setPerformers(res.data);
            });

        // let p = Api.get('partners/relationships/accepted')
        //     .then(res => {
        //         return res.data;
        //     });

        // Promise.all([e, p])
        //     .then(values => {
        //         let merged = values[0].concat(values[1]);

        //         setPerformers(merged);
        //     });
    }, []);

    const loadParts = () => {

        if (requestRef.current) {
            requestRef.current.cancel();
        }

        const cancelToken = Axios.CancelToken.source();

        requestRef.current = cancelToken;

        let url = 'ordered-parts/all?page=' + parts.page;

        Object.entries(parts.filter).map(filter => {
            url += '&' + filter[0] + '=' + filter[1];
        });

        setParts(prev => ({
            ...prev,
            loading: true
        }));

        Api.get(url, { cancelToken: cancelToken.token })
            .then(res => {
                setParts(prev => ({
                    ...prev,
                    loading: false,
                    data: res.data.items,
                    total: res.data.total,
                    pages: res.data.pages
                }));
            }).catch(err => {
                if (Axios.isCancel(err)) {
                    // 
                } else {
                    setParts(prev => ({
                        ...prev,
                        loading: false
                    }));
                }
            });
    }

    // update
    const handleEdit = id => {
        let modal = modalEditRef.current;

        modal.edit(id);

        modal.onSave(() => {
            handleReload();
        });
    }

    const handleUpdate = part => {
        handleReload();
    }

    // delete
    const handleDelete = id => {
        let modal = modalQuestionRef.current;

        modal.open(id);
        modal.onSuccess(() => {
            handleDestroy(id);
        })
    }

    const handleDestroy = id => {

        Api.post('ordered-parts/delete', {
            id: id
        }).then(res => {
            if (res.data.success) {
                handleRefresh();
            }
        });
    }

    const handleRefresh = () => {
        setParts(prev => ({
            ...prev,
            page: 1,
            update: new Date().getTime()
        }));
    }

    const handleReload = () => {
        setParts(prev => ({
            ...prev,
            update: new Date().getTime()
        }));
    }

    const handleChangePerformer = (rid, eid) => {
        let array = [...parts.data];

        let updated = array.map(p => {
            if (Number(p.reservation_id) === Number(rid)) {
                p.reservation.employee_id = Number(eid);
            }

            return p;
        });

        setParts(prev => ({
            ...prev,
            data: updated
        }));
    }

    // Search
    const handleSearch = (key, val) => {
        clearTimeout(timeout);

        setParts(prev => ({
            ...prev,
            loading: true,
            filter: {
                ...prev.filter,
                [key]: val,
            },
        }));

        timeout = setTimeout(() => {
            setParts(prev => ({
                ...prev,
                page: 1,
                setFilter: new Date().getTime()
            }));
        }, 300);
    }

    const handlePage = page => {
        setParts(prev => ({ ...prev, page: page.selected + 1, setFilter: new Date().getTime() }));
    }

    const handleHistory = id => {
        activityModalRef.current.open(id, 'App\\OrderedPart');
    }

    return (
        <>

            <EditPart
                ref={modalEditRef}
            />

            <ActivityModal
                ref={activityModalRef}
            />

            <Question
                ref={modalQuestionRef}
                mainMessage={trans('headings.confirmDelete')}
                secondaryMessage={trans('subheadings.confirmDelete')}
            />

            <Filter
                filter={parts.filter}
                update={parts.update}
                showCustomerType={true}
                showStatuses={true}
                showDates={true}
                handleSearch={handleSearch}
            />

            <div className="pannel">
                <h3>
                    {trans('headings.usedVanParts')}
                </h3>

                {parts.loading
                    ?
                    <Skeleton height={45} count={10} />
                    :
                    <>

                        <Table
                            parts={parts.data}
                            filter={parts.filter}
                            performers={performers}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            handleHistory={handleHistory}
                            handleReload={handleReload}
                            handleChangePerformer={handleChangePerformer}
                        />

                        <Pagination
                            pages={parts.pages}
                            page={parts.page}
                            handlePage={handlePage}
                        />
                    </>
                }
            </div>

        </>
    )
}

export default Used;